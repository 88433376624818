module.exports = {
  fr: {
    "Coûts d’exploitation Eolien": "Coûts d’exploitation Eolien",
    "Coûts Stockage virtuel": "Coûts Stockage virtuel",
    "Coûts d’exploitation Batterie": "Coûts d’exploitation Batterie",
    "Coûts d’exploitation PV": "Coûts d’exploitation PV",
    "Coûts d’exploitation PV2": "Coûts d’exploitation PV2",
    "Coûts d’exploitation Groupe éléctrogène":
      "Coûts d’exploitation Groupe éléctrogène",
    "Achat combustible": "Achat combustible",
    "Coûts d’exploitation Pile à combustible":
      "Coûts d’exploitation Pile à combustible",
    "Achats hydrogène": "Achats hydrogène",
    "Achats réseau": "Achats réseau",
    "Revente PV": "Vente PV",
    "Revente PV2": "Vente PV2",
    "Revente Eolien": "Vente Eolien",
    "Revenus Effacement Spot": "Revenus Effacement Spot",
    "Revenus Effacement Complément de Rémunération":
      "Revenus Effacement Complément de Rémunération",
    "Revenus Effacement Mécanisme de Capacité":
      "Revenus Effacement Mécanisme de Capacité",
    "Coûts d’exploitation électrolyse": "Coûts d’exploitation électrolyse",
    "Revente H2": "Vente H2",
    "Achats PPA PV": "Achats PPA PV",
    "Achats PPA PV2": "Achats PPA PV2",
    "Achats PPA Eolien": "Achats PPA Eolien",
    IFER: "IFER",
    "IFER - PV2": "IFER - PV2",
    "Prime à l'autoconso - PV": "Prime à l'autoconso - PV",
    "Prime à l'autoconso - PV2": "Prime à l'autoconso - PV2",

    "Opex Raccordement": "Opex Raccordement",

    "Opex Electrolyse": "Opex Electrolyse",
    "Opex ECS": "Opex ECS",
    "Vente Pile à combustible": "Vente Pile à combustible",
    "Vente Pile à combustible - PPA": "Vente Pile à combustible - PPA",
    "Vente Pile à combustible - SPOT": "Vente Pile à combustible - SPOT",
    "Vente PV - Capacité": "Vente PV - Capacité",
    "Vente PV - Complément rémunération": "Vente PV - Complément rémunération",
    "Vente PV - PPA": "Vente PV - PPA",
    "Vente PV - SPOT": "Vente PV - SPOT",
    "Vente PV - Appel d'offre": "Vente PV - Appel d'offre",
    "Vente Eolien - Capacité": "Vente Eolien - Capacité",
    "Vente Eolien - Complément rémunération":
      "Vente Eolien - Complément rémunération",
    "Vente Eolien - PPA": "Vente Eolien - PPA",
    "Vente Eolien - SPOT": "Vente Eolien - SPOT",
    "Vente Batterie - FCR": "Vente Batterie - FCR",
    "Vente Batterie - SPOT": "Vente Batterie - SPOT",
    "Vente Batterie - PPA": "Vente Batterie - PPA",
    "Vente Batterie - Capacité": "Vente Batterie - Capacité",
    "Vente H2": "Vente H2",
    "Complément PPA - PV": "Complément PPA - PV",
    "Complément PPA - Eolien": "Complément PPA - Eolien",
    Taxes: "Impôts",
    Remboursements: "Remboursements",
    Intérêts: "Intérêts",
    gridBeforeOptim: "Facture initiale",
    gridAfterOptim: "Facture après autoconsommation",
    "Prime PV": "Prime PV",
    "Prime PV2": "Prime PV2",
  },
  en: {
    "Coûts d’exploitation Eolien": "Opex Wind turbine",
    "Coûts Stockage virtuel": "Virtual storage costs",
    "Coûts d’exploitation Batterie": "Opex Battery",
    "Coûts d’exploitation PV": "Opex Photovoltaic",
    "Coûts d’exploitation PV2": "Opex Photovoltaic 2",
    "Coûts d’exploitation Groupe éléctrogène": "Opex Engine-generator",
    "Achat combustible": "Fuel cost",
    "Coûts d’exploitation Pile à combustible": "Opex Fuel cell",
    "Achats hydrogène": "Hydrogen purchase",
    "Achats réseau": "Grid",
    "Revente PV": "Photovoltaic sales",
    "Revente PV2": "Photovoltaic 2 sales",
    "Revente Eolien": "Wind turbine sales",
    "Revenus Effacement Spot": "Withdrawal SPOT revenus",
    "Revenus Effacement Complément de Rémunération":
      "Withdrawal complement revenus",
    "Revenus Effacement Mécanisme de Capacité": "Withdrawal FCR revenus",
    "Coûts d’exploitation électrolyse": "Opex electrolysis",
    "Revente H2": "H2 sales",
    "Achats PPA PV": "Photovoltaic PPA purchase",
    "Achats PPA PV2": "Photovoltaic 2 PPA purchase",
    "Achats PPA Eolien": "Wind turbine PPA purchase",
    IFER: "IFER",
    "IFER - PV2": "IFER - Photovoltaic 2",
    "Prime à l'autoconso - PV": "Photovoltaic self-consumption bonus",
    "Prime à l'autoconso - PV2": "Photovoltaic 2 self-consumption bonus",

    "Opex Raccordement": "Opex Connection",

    "Coûts d’exploitation Electrolyse": "Opex Electrolysis",
    "Coûts d’exploitation ECS": "Opex ECS",
    "Vente Pile à combustible": "Fuel cell sales",
    "Vente Pile à combustible - PPA": "Fuel cell sales - PPA",
    "Vente Pile à combustible - SPOT": "Fuel cell sales - SPOT",
    "Vente PV - Capacité": "Solar panel - Capacity",
    "Vente PV - Complément rémunération": "Photovoltaic sales - Complement",
    "Vente PV - PPA": "Photovoltaic sales - PPA",
    "Vente PV - SPOT": "Solar panel sales - SPOT",
    "Vente PV - Appel d'offre": "Solar panel sales- Appel d'offre",
    "Vente Eolien - Capacité": "Wind turbine sales - Capacité",
    "Vente Eolien - Complément rémunération": "Wind turbine sales - Complement",
    "Vente Eolien - PPA": "Wind turbine sales - PPA",
    "Vente Eolien - SPOT": "Wind turbine sales - SPOT",
    "Vente Batterie - FCR": "Battery sales - FCR",
    "Vente Batterie - SPOT": "Battery sales - SPOT",
    "Vente Batterie - PPA": "Battery sales - PPA",
    "Vente Batterie - Capacité": "Battery sales - Capacité",
    "Vente H2": "H2 sales",
    "Complément PPA - PV": "Complement PPA - Photovoltaic",
    "Complément PPA - Eolien": "Complement PPA - Wind turbine",
    Taxes: "Taxes",
    Remboursements: "Repayment",
    Intérêts: "Interests",
    gridBeforeOptim: "reference grid bill",
    gridAfterOptim: "Grid bill after optimization",
    "Prime PV": "PV Prime",
    "Prime PV2": "PV2 Prime",
  },
};
