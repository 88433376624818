export const fr_Hypothesis = {
  consumer: {
    paramsOptimization: "Paramètres d'optimisation",
    paramsWT: "Eolien",
    paramsBattery: "Batterie - stockage",
    paramsConversion: "Batterie - conversion",
    paramsChargingStations: "Bornes de recharge",
    paramsGrid: "Réseau",
    paramsFuelCell: "Pile à combustible",
    paramsElectrolyse: "Electrolyse",
    paramsH2Storage: "Stockage d'hydrogène",
    paramsECS: "ECS",
    paramsThermalStorage: "Stockage Thermique",
    paramsVS: "Stockage virtuel",
    paramsGen: "Groupe électrogène",
    paramsPV: "Photovoltaïque",
    paramsPV2: "Photovoltaïque 2",
    //
    batteryLifespan: "Durée de vie (ans)",
    batteryMaxSoc: "SOC maximal",
    batteryMinSoc: "SOC minimal",
    batteryYield: "Rendement round trip DC",
    batteryFcrOn: "Réglage de fréquence",

    batteryCapexTableA: "CAPEX ({{currency}}/kWh)",
    batteryCapexTableV: "Plages de capacités (kWh)",
    batteryOpex: "OPEX ({{currency}}/kWh/an)",
    batteryDegradRate: "Taux de dégradation (%/an)",

    conversionCapexTableA: "CAPEX ({{currency}}/kW)",
    conversionCapexTableV: "Plage de puissances (kW)",
    conversionLifespan: "Durée de vie (ans)",
    conversionYield: "Rendement conversion",
    conversionOpex: "OPEX ({{currency}}/kW/an)",

    fuelCellCapexTableA: "CAPEX ({{currency}}/kW)",
    fuelCellCapexTableV: "Plages de puissances (kW)",
    fuelCellAverageH2Consumption:
      "Consommation moyenne (kg d'hydrogène / kWh électrique produit)",
    fuelCellLifespan: "Durée de vie (ans)",
    h2Cost: "Coût de l'hydrogène ({{currency}}/kg)",
    fuelCellOpex: "OPEX ({{currency}}/kW/h)",
    h2PurchaseInflation: "Inflation prix d'achat de l'hydrogène (%/an)",

    genCapexTableA: "CAPEX ({{currency}}/kVA)",
    genCapexTableV: "Plage de puissances (kVA)",
    fuelCost: "Coût de carburant ({{currency}}/L)",
    genAverageFuelConsumption:
      "Consommation moyenne (L de carburant / kWh électrique produit)",
    genLifespan: "Durée de vie (ans)",
    genOpex: "OPEX ({{currency}}/KVA/H)",
    fuelPurchaseInflation: "Inflation prix d'achat du carburant (%/an)",

    purchaseInflation: "Inflation du tarif d'achat (%/an)",
    energyInflationRates: "Inflation du tarif d'achat (%/an)",

    actualizationRate: "Taux d'actualisation (%)",
    CO2ReductionRate: "Objectif réduction CO2 (%)",
    aoeNebefOn: "Effacements",
    fullBackup: "Objectif alimentation secours - totale (h)",
    medianBackup: "Objectif alimentation secours - médiane (h)",
    tailBackup: "Objectif alimentation secours - talon (h)",
    onSiteProductionRate: "Objectif d'autoproduction (%)",
    energyInflation: "Inflation du tarif d'achat (%/an)",

    pvCapexTableA: "CAPEX ({{currency}}/kWc)",
    pvCapexTableV: "Plage de puissances (kWc)",
    pvOpex: "OPEX ({{currency}}/kWc/an)",

    useCase: "Cas d'usage",

    orientation: "Orientation (°)",
    panelYield: "Rendement STC",
    pvLifespan: "Durée de vie (ans)",
    pvSystemLoss: "Pertes systèmes DC/AC (coef.)",

    pvSaleInflation: "Inflation du tarif de vente",
    pvPurchaseInflation: "Inflation du tarif d'achat",
    pvSellingPrice: "Tarif de vente (c{{currency}}/kWh)",
    pvPurchasePrice: "Tarif d'achat (c{{currency}}/kWh)",
    pvAutoconsoRate: "Part minimale du photovoltaïque dans la consommation (%)",
    tilt: "Inclinaison (°)",
    pvUsefulArea: "Surface utile (%)",
    pvSurface: "Surface utile (m²)",
    pvDegradRate: "Taux de dégradation (%)",
    pvMinAutoConsoRate: "Taux d'autoconsommation minimal (%)",
    pvMaxAutoConsoRate: "Taux d'autoconsommation maximal (%)",
    pvInvertorPowerRate: "Puissance de l'onduleur (% de la puissance crête)",
    pvSub: "Prime ({{currency}}/KWC/AN)",

    //

    pv2CapexTableA: "CAPEX ({{currency}}/kWc)",
    pv2CapexTableV: "Plage de puissances (kWc)",
    pv2Opex: "OPEX ({{currency}}/kWc/an)",

    useCase2: "Cas d'usage",

    orientation2: "Orientation (°)",
    panelYield2: "Rendement STC (%)",
    pv2Lifespan: "Durée de vie (ans)",
    pv2SystemLoss: "Pertes systèmes (coef.)",

    pv2SaleInflation: "Inflation du tarif de vente",
    pv2PurchaseInflation: "Inflation du tarif d'achat",
    pv2SellingPrice: "Tarif de vente (c{{currency}}/kWh)",
    pv2PurchasePrice: "Tarif d'achat (c{{currency}}/kWh)",
    pv2AutoconsoRate: "Part minimale du PV dans la consommation (%)",
    tilt2: "Inclinaison (°)",
    pv2UsefulArea: "Surface utile (%)",
    pv2Surface: "Surface utile",
    pv2DegradRate: "Taux de dégradation (%)",
    pv2MinAutoConsoRate: "Taux d'autoconsommation minimal (%)",
    pv2MaxAutoConsoRate: "Taux d'autoconsommation maximal (%)",
    pv2InvertorPowerRate:
      "Puissance de l'onduleur (rapport de la puissance crête)",
    pv2Sub: "Prime ({{currency}}/KWC/AN)",

    //

    vsEnergyRate: "Prix sur énergie fournie (c{{currency}}/kWh)",
    vsFixRate: "Prix fixe ({{currency}}/an)",
    vsPVRate: "Prixe sur puissance PV ({{currency}}/kWc/an)",

    wtPower: "Puissance (kW)",
    capexWindTurbineTableA: "CAPEX ({{currency}}/KW)",
    capexWindTurbineTableV: "Plage de puissances (kW)",
    opexWindTurbineTableA: "OPEX ({{currency}}/kW/an)",
    wtLifespan: "Durée de vie (années)",
    wtSellingPrice: "Tarif de vente (c{{currency}}/kWh)",
    wtYield: "Rendement",
    wtHeight: "Hauteur de l'éolienne (m)",
    wtAutoconsoRate: "Taux d'autoconsommation minimal(%)",
    wtSaleInflation: "Inflation du tarif de vente",
    wtPurchaseInflation: "Inflation du tarif d'achat",
    wtPurchasePrice: "Tarif d'achat",
    wtInflation: "Inflation du tarif de vente (%/an)",
    leasingLifespan: "Durée des Opex ou du PPA d'achat",

    electrolysePower: "Plages de puissances (kW)",
    electrolyseLifespan: "Durée de vie (ans)",
    electrolyseAverageConso:
      "Consommation moyenne (kWh électrique / kg d'hydrogène)",
    electrolyseCapexTableV: "Plages de puissances (kW)",
    electrolyseCapexTableA: "CAPEX ({{currency}}/kW)",
    electrolyseOpex: "OPEX ({{currency}}/kW/an)",

    h2StorageCapacity: "Capacité (kg)",
    h2StorageLifespan: "Durée de vie (ans)",
    h2SellingPrice: "Prix de vente de l'hydrogène ({{currency}}/kg)",
    h2StorageCapexTableV: "Plage de capacités (kg)",
    h2StorageCapexTableA: "CAPEX ({{currency}}/kg)",
    h2StorageOpex: "OPEX ({{currency}}/kg/an)",
    h2Inflation: "Inflation du prix de vente de l'hydrogène",
    saleInflation: "Inflation du tarif de vente (%)",
    demandGrowth: "Taux de croissance (%/an)",

    pvInflation: "Inflation du tarif de vente (%/an)",
    pv2Inflation: "Inflation du tarif de vente (%/an)",
    opexInflation: "Inflation des OPEX & achats PPA (%)",

    // COMPLEMENTARY
    pvPeakPower: "Puissance crête (kWc)",
    pvPanelPeakPower: "Puissance crête panneau",
    pvShare: "Table des inclinaisons/orientations (%)",
    pvPpaBuilderSlots: "Tranches PPA",
    addTurpePV: "Ajout TURPE",
    pvProductible: "Productible",
    subscribedPowers: "Puissances souscrites",
    energyPrices: "Tarifs énergie",
    subscriptionRate: "Tarif d'abonnement",
    offPeakHours: "Heures creuses",
    demandMultiplier: "Coefficient multiplicateur de la consommation",
    batteryCapacity: "Capacité de la batterie",
    conversionPower: "Puissance de conversion",
    stations: "Bornes {{index}}",
    vsTurpe: "TURPE stockage virtuel",
    vsOn: "Avec stockage virtuel",
    vsMaxCapacity: "Capacité maximale",
    wtPpaBuilderSlots: "Tranches PPA",
    wtCapexTableA: "CAPEX ({{currency}}/kW)",
    addTurpeWT: "Ajout TURPE",
    wtCapexTableV: "Plage de puissances (kW)",
    wtOpex: "OPEX ({{currency}}/kW/an)",
    genPower: "Puissance",
    fuelCellPower: "Puissance",
    h2SaleInflation: "Inflation prix de vente del'hydrogène",

    debtDuration: "Durée du prêt (ans)",
    debtRatio: "Pourcentage d'emprunt (%)",
    taxRate: "Taux d'imposition (%)",
    projectLifespan: "Horizon du projet",
    optimizationYear: "Année d'optimisation",
    interestRate: "Taux d'intérêt (%)",
    discountRate: "Taux d'actualisation (%)",

    pv2PanelPeakPower: "Puissance crête",
    pv2PeakPower: "Puissance crête",
    pv2Productible: "Productible",
    pv2PpaBuilderSlots: "Tranches PPA",
    addTurpePV2: "Avec TURPE",
    pv2Share: "Table des inclinaisons / orientations (%)",
  },
  supplier: {
    ppaInflation: "Inflation PPA",
    capaInflation: "Inflation mécanisme de capacité",
    crInflation: "Inflation complément de réménuération",
    pvInflation: "Inflation du tarif de vente (%/an)",
    wtInflation: "Inflation du tarif de vente (%/an)",
    capaReferencePrice: "Prix de référence ({{currency}}/kW/an)",
    fcrInflation: "Inflation FCR (%)",
    pvCapaOn: "Mécanisme de capacité",
    pvComplementRemunOn: "Complément de rémunération",
    pvPpaEnergyRate: "Part de la consommation d'énergie annuelle (%)",
    pvPpaType: "Type de PPA",
    pvSpotOn: "SPOT",
    pvTarifAO: "Tarif (c{{currency}}/kWh)",
    batteryCapaOn: "Mécanisme de capacité",
    batteryPpaOn: "PPA",
    batterySpotOn: "SPOT",
    capexWtTableA: "CAPEX ({{currency}}/kW)",
    capexWtTableV: "Plage de puissances (kW)",
    opexWtTableA: "OPEX ({{currency}}/kW/an)",
    wtAcousticLoss: "Pertes par bridage acoustique (%)",
    wtCapaOn: "Mécanisme de capacité",
    wtComplementRemunOn: "Complément de rémunération",
    wtElectricalLoss: "Pertes électriques (%)",
    wtEnvironmentalLoss: "Pertes par bridage environmental (%)",
    wtFieldAvailability: "Disponibilité du parc (%)",
    wtMastHeight: "Hauteur du mât",
    wtNb: "Nombre d'unités",
    wtOtherLoss: "Autres pertes (%)",
    wtPpaEnergyRate: "Part de la consommation d'énergie annuelle (%)",
    wtPpaType: "Type de PPA",
    wtShearCoef: "Coefficient de cisaillement",
    wtSpotOn: "SPOT",
    wtTarifAO: "Tarif (c{{currency}}/kWh)",
    wtTechnicalLoss: "Pertes techniques (%)",
    wtType: "Type d'éolienne",
    wtWakeLoss: "Pertes par sillage (%)",
    genPpaOn: "Avec PPA",
    genSpotOn: "Marché SPOT",
    gridConnectionFee: "CAPEX raccordement ({{currency}})",
    gridConnectionPower: "Puissance de raccordement (kW)",
    capexGridConnection: "CAPEX raccordement ({{currency}}/kW)",

    opexGridConnection: "OPEX raccordement ({{currency}}/kW)",
    priceBuilder: "Tarifs personnalisés",
    priceBuilderSlots: "Tranches tarifs personnalisés",
    fuelCellPpaOn: "PPA",
    fuelCellSpotOn: "SPOT",
    pv2CapaOn: "Mécanisme de capacité",
    pv2ComplementRemunOn: "Complément de rémunération",
    pv2PpaEnergyRate: "Part de la consommation d'énergie annuelle (%)",
    pv2PpaType: "Type de PPA",
    pv2SpotOn: "SPOT",
    pv2TarifAO: "Tarif (c{{currency}}/kWh)",
  },
};

export const en_Hypothesis = {
  consumer: {
    paramsOptimization: "Optimization parameters",
    paramsWT: "Wind turbine",
    paramsBattery: "Battery - storage",
    paramsConversion: "Battery - conversion",
    paramsChargingStations: "Charging stations",
    paramsGrid: "Grid",
    paramsFuelCell: "Fuel cell",
    paramsElectrolyse: "Electrolysis",
    paramsH2Storage: "Hydrogen storage",
    paramsVS: "Virtual storage",
    paramsGen: "Engine generator",
    paramsPV: "Photovoltaic",
    paramsPV2: "Photovoltaic 2",
    batteryLifespan: "Lifespan (years)",
    batteryMaxSoc: "Maximum SoC",
    batteryMinSoc: "Minimum SoC",
    batteryYield: "Round trip yield",
    batteryFcrOn: "FCR",
    //
    batteryCapexTableA: "CAPEX ({{currency}}/kWh)",
    batteryCapexTableV: "Capacity ranges (kWh)",
    opexBattery: "OPEX ({{currency}}/kWh/year)",
    batteryDegradRate: "Degradation rate (%/an)",

    conversionCapexTableA: "CAPEX ({{currency}}/kW)",
    conversionCapexTableV: "Power ranges (kW)",
    conversionLifespan: "Lifespan (years)",
    conversionYield: "Conversion yield",
    conversionOpex: "OPEX ({{currency}}/kW/yean)",

    fuelCellCapexTableA: "CAPEX ({{currency}}/kW)",
    fuelCellCapexTableV: "Power ranges (kW)",
    fuelCellAverageH2Consumption:
      "Average consumption (kg of hydrogen / kWh produced)",
    fuelCellLifespan: "Lifespan (years)",
    h2Cost: "Hydrogen cost ({{currency}}/kg)",
    fuelCellOpex: "OPEX ({{currency}}/kW/h)",
    h2PurchaseInflation: "Hydrogen purchase price inflation (%/year)",

    genCapexTableA: "CAPEX ({{currency}}/kVA)",
    genCapexTableV: "Power ranges (kVA)",
    fuelCost: "Fuel cost ({{currency}}/L)",
    genAverageFuelConsumption: "Average consumption (L of fuel / kWh produced)",
    genLifespan: "Lifespan (years)",
    genOpex: "OPEX ({{currency}}/kVA/H)",
    fuelPurchaseInflation: "Fuel cost inflation (%/year)",

    purchaseInflation: "Purchase price inflation (%/year)",

    actualizationRate: "Actualization rate (%)",
    CO2ReductionRate: "CO2 reduction objective (%)",
    aoeNebefOn: "Nebef market",
    fullBackup: "Backup power objective - full (h)",
    medianBackup: "Backup power objective - median (h)",
    tailBackup: "Backup power objective - idle (h)",
    onSiteProductionRate: "Self-supply objective (%)",
    energyInflation: "Purchase price inflation (%/year)",
    energyInflationRates: "Purchase price inflation (%/year)",

    pvCapexTableA: "CAPEX ({{currency}}/kWp)",
    pvCapexTableV: "Power ranges (kWp)",
    pvOpex: "OPEX ({{currency}}/kWp/year)",

    useCase: "Use case",

    orientation: "Orientation (°)",
    panelYield: "STC Yield",
    pvLifespan: "Lifespan (years)",
    pvSystemLoss: "DC/AC System loss (%)",

    pvSaleInflation: "Selling price inflation",
    pvPurchaseInflation: "Purchase price inflation",
    pvSellingPrice: "Selling price (c{{currency}}/kWh)",
    pvPurchasePrice: "Purchase price (c{{currency}}/kWh)",
    pvAutoconsoRate: "Minimum self-consumption rate (%)",
    tilt: "Tilt (°)",
    pvUsefulArea: "Useful area (%)",
    pvSurface: "Useful area (m²)",
    pvDegradRate: "Degradation rate (%)",
    pvMinAutoConsoRate: "Minimum self-consumption rate (%)",
    pvMaxAutoConsoRate: "Maximum self-consumption rate (%)",
    pvInvertorPowerRate: "Invertor power (% of peak power)",
    pvSub: "Prime ({{currency}}/kWp/year)",

    //

    pv2CapexTableA: "CAPEX ({{currency}}/kWp)",
    pv2CapexTableV: "Power ranges (kWp)",
    pv2Opex: "OPEX ({{currency}}/kWp/year)",

    useCase2: "Use case",

    orientation2: "Orientation (°)",
    panelYield2: "STC Yield (%)",
    pv2Lifespan: "Lifespan (years)",
    pv2SystemLoss: "System loss (%)",

    pv2SaleInflation: "Selling price inflation",
    pv2PurchaseInflation: "Purchase price inflation",
    pv2SellingPrice: "Selling price (c{{currency}}/kWh)",
    pv2PurchasePrice: "Purchase price (c{{currency}}/kWh)",
    pv2AutoconsoRate: "Minimum self-consumption rate (%)",
    tilt2: "Tilt (°)",
    pv2UsefulArea: "Useful area (%)",
    pv2Surface: "Useful area",
    pv2DegradRate: "Degradation rate (%)",
    pv2MinAutoConsoRate: "Minimum self-consumption rate (%)",
    pv2MaxAutoConsoRate: "Maximum self-consumption rate (%)",
    pv2InvertorPowerRate: "Invertor power (% of peak power)",
    pv2Sub: "Prime ({{currency}}/kWp/year)",

    //

    vsEnergyRate: "Price on supplied energy (c{{currency}}HTVA/kWh)",
    vsFixRate: "Fixed price ({{currency}}HTVA/an)",
    vsPVRate: "Price on photovoltaic power ({{currency}}HTVA/kWp/year)",

    wtPower: "Power (kW)",
    capexWindTurbineTableA: "CAPEX ({{currency}}/KW)",
    capexWindTurbineTableV: "Power ranges (kW)",
    opexWindTurbineTableA: "OPEX ({{currency}}/kW/year)",
    wtLifespan: "Lifespan (years)",
    wtSellingPrice: "Selling price (c{{currency}}/kWh)",
    wtYield: "Yield",
    wtHeight: "Turbine height (m)",
    wtAutoconsoRate: "Minimum self-consumption rate (%)",
    wtSaleInflation: "Selling price inflation",
    wtPurchaseInflation: "Purchase price inflation",
    wtPurchasePrice: "Purchase price",
    wtInflation: "Selling price inflation (%/year)",
    leasingLifespan: "Lease duration or PPA contract",

    electrolysePower: "Power ranges (kW)",
    electrolyseLifespan: "Lifespan (years)",
    electrolyseAverageConso: "Averge consumption (kWh / kg hydrogen)",
    electrolyseCapexTableV: "Power ranges (kW)",
    electrolyseCapexTableA: "CAPEX ({{currency}}/kW)",
    electrolyseOpex: "OPEX ({{currency}}/kW/year)",

    h2StorageCapacity: "Capacity (kg)",
    h2StorageLifespan: "Lifespan (years)",
    h2SellingPrice: "Hydrogen selling price ({{currency}}/kg)",
    h2StorageCapexTableV: "Capacity ranges (kg)",
    h2StorageCapexTableA: "CAPEX ({{currency}}/kg)",
    h2StorageOpex: "OPEX ({{currency}}/kg/year)",
    h2Inflation: "Hydrogen selling price inflation",
    saleInflation: "Selling price inflation (%)",
    demandGrowth: "Growth rate (%/year)",

    pvInflation: "Selling price inflation (%/year)",
    pv2Inflation: "Selling price inflation (%/year)",
    opexInflation: "Opex inflation and PPA purchase (%)",

    // COMPLEMENTARY
    pvPeakPower: "Peak power (kWp)",
    pvPanelPeakPower: "Panel peak power",
    pvShare: "Tilt/orientation table (%)",
    pvPpaBuilderSlots: "PPA ranges",
    addTurpePV: "With TURPE",
    pvProductible: "Potential",
    subscribedPowers: "Subscribed powers",
    energyPrices: "Energy prices",
    subscriptionRate: "Subscription prices",
    offPeakHours: "Offpeak hours",
    demandMultiplier: "Demand multiplier",
    batteryCapacity: "Battery capacity",
    conversionPower: "Conversion power",
    stations: "Stations {{index}}",
    vsTurpe: "Virtual storage TURPE",
    vsOn: "With virtual storage",
    vsMaxCapacity: "Max capacity",
    wtPpaBuilderSlots: "PPA ranges",
    wtCapexTableA: "CAPEX ({{currency}}/kW)",
    addTurpeWT: "With TURPE",
    wtCapexTableV: "Power ranges (kW)",
    wtOpex: "OPEX ({{currency}}/kW/year)",
    genPower: "Power",
    fuelCellPower: "Power",
    h2SaleInflation: "Hydrogen selling price inflation",

    debtDuration: "Debt duration (years)",
    debtRatio: "Debt ratio (%)",
    taxRate: "Tax rate (%)",
    projectLifespan: "Project lifespan",
    optimizationYear: "Optimization year",
    interestRate: "Interest rate (%)",
    discountRate: "Discount rate (%)",

    pv2PanelPeakPower: "Panel peak power",
    pv2PeakPower: "Peak power",
    pv2Productible: "Potential",
    pv2PpaBuilderSlots: "PPA ranges",
    addTurpePV2: "With TURPE",
    pv2Share: "Tilt/orientation table (%)",
  },
  supplier: {
    ppaInflation: "PPA inflation (%/year)",
    capaInflation: "Capacity mechanism inflation (%/year)",
    crInflation: "Complement inflation",
    pvInflation: "Selling price inflation (%/year)",
    wtInflation: "Selling price inflation (%/year)",
    capaReferencePrice: "Reference price ({{currency}}/kW/year)",
    fcrInflation: "FCR Inflation (%)",
    pvCapaOn: "Capacity mechanism",
    pvComplementRemunOn: "Complement",
    pvPpaEnergyRate: "Share of annual consumption (%)",
    pvPpaType: "PPA type",
    pvSpotOn: "SPOT",
    pvTarifAO: "Price (c{{currency}}/kWh)",
    batteryCapaOn: "Capacity mechanism",
    batteryPpaOn: "PPA",
    batterySpotOn: "SPOT",
    capexWtTableA: "CAPEX ({{currency}}/kW)",
    capexWtTableV: "Power ranges (kW)",
    opexWtTableA: "OPEX ({{currency}}/kW/year)",
    wtAcousticLoss: "Acoustic losses (%)",
    wtCapaOn: "Capacity mechanism",
    wtComplementRemunOn: "Complement",
    wtElectricalLoss: "Electrical losses (%)",
    wtEnvironmentalLoss: "Environmental losses (%)",
    wtFieldAvailability: "Field availability (%)",
    wtMastHeight: "Mast height",
    wtNb: "Number of unit",
    wtOtherLoss: "Other losses (%)",
    wtPpaEnergyRate: "Share of annual consumption (%)",
    wtPpaType: "PPA type",
    wtShearCoef: "Shear coefficient",
    wtSpotOn: "SPOT",
    wtTarifAO: "Price (c{{currency}}/kWh)",
    wtTechnicalLoss: "Technical losses (%)",
    wtType: "Wind turbine type",
    wtWakeLoss: "Wake losses (%)",
    genPpaOn: "PPA",
    genSpotOn: "SPOT",
    gridConnectionFee: "Connection CAPEX ({{currency}})",
    gridConnectionPower: "Connection power (kW)",
    capexGridConnection: "Connection CAPEX ({{currency}}/kW)",

    opexGridConnection: "Connection OPEX ({{currency}}/kW)",
    priceBuilder: "Custom price ranges",
    priceBuilderSlots: "Price ranges",
    fuelCellPpaOn: "PPA",
    fuelCellSpotOn: "SPOT",
    pv2CapaOn: "Capacity mechanism",
    pv2ComplementRemunOn: "Complement",
    pv2PpaEnergyRate: "Share of annual consumption (%)",
    pv2PpaType: "PPA type",
    pv2SpotOn: "SPOT",
    pv2TarifAO: "Price (c{{currency}}/kWh)",
  },
};
