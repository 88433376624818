export const fr_tips = {
  tips1: {
    highlight:
      "Lors de la création d’un site, au moment d’intégrer l’adresse, vous pouvez tout simplement utiliser le nom",
    content: "du bâtiment ou de la société. Par exemple « Stade de France ».",
  },
  tips2: {
    highlight:
      "A l’étape 3 de la création d’un site, pensez à renseigner vos tarifs d’achat d’électricité en centimes d’euros / kWh et non en € / kWh.",
    content: "Par exemple «11,4» et non pas «0,114»",
  },
  tips3: {
    highlight:
      "Si vous ne trouvez pas le tarif des heures de pointe sur les factures d’hiver d’un site HTA,",
    content:
      "c’est que le fournisseur les facture au même tarif que les heures pleines. Recopiez simplement cette même valeur dans pointe.",
  },
  tips4: {
    highlight: "L’électricité se propage quasiment à la vitesse de lumière,",
    content: "soit approximativement à 299 792 458 m/s.",
  },
  tips5: {
    highlight: "Même si vos mesures ne couvrent pas 365 jours,",
    content:
      "les coûts et les revenus de votre projet d’autoconsommation seront projetés et affichés pour une année complète dans les résultats d’optimisation.",
  },
  tips7: {
    highlight:
      "Taux d’autoconsommation = proportion de l'énergie photovoltaïque produite que vous consommez sur place.",
    content:
      "Taux d’autoproduction = part de l’énergie provenant du photovoltaïque dans l’énergie totale consommée.",
  },
  tips8: {
    highlight:
      "Le Soleil produit 15 000 fois plus d’énergie que ce que consomme la population mondiale chaque année.",
    content:
      "La lumière solaire qui atteint la surface de la Terre à chaque minute est suffisante pour satisfaire la consommation énergétique du monde durant une année entière.",
  },
  tips9: {
    highlight:
      "Vous avez une question ou une suggestion dont vous souhaitez nous faire part ?",
    content:
      "Contactez-nous directement via le chat. Nous vous répondrons très rapidement !",
  },
  tips10: {
    highlight: "Optiwize",
    content:
      "vous permet de simuler divers schémas de financement, des systèmes simples ou hybrides et des contextes variés avec le même outil",
  },
  tips11: {
    highlight: "Optiwize",
    content:
      "vous permet de mener une étude complète depuis l’extraction des données, et l’analyse de coûts, jusqu’à l’optimisation du dimensionnement et le bilan financier.",
  },
  tips12: {
    highlight: "Retrouvez et téléchargez le tutoriel du logiciel",
    content:
      "en cliquant sur « ? aide » en haut à droite de la barre de navigation.",
  },
  tips13: {
    highlight:
      "Vous pouvez ajouter votre logo dans la rubrique “mon compte” > “personnalisation”",
    content:
      "pour que celui-ci ressorte automatiquement sur vos téléchargements PDF.",
  },
  tips14: {
    highlight:
      "Configuez des scénarios types dans la rubrique “mon compte” > “configuration par défaut”",
    content:
      "et réutilisez-les sur vos nouveaux projets pour appliquer le paramétrage.",
  },
  tips15: {
    highlight: "Pour comparer un nouveau cas de figure avec les précédents,",
    content:
      "dupliquez un scénario avant de modifier le paramètre concerné et de lancer le calcul.",
  },
  tips16: {
    highlight:
      "Saviez-vous que les compteurs électriques qui calculent la quantité d’énergie consommée n’ont pas toujours existé ?",
    content:
      "À la fin du 19ᵉ siècle, ceux qui utilisaient l’électricité payaient une redevance calculée sur la base du nombre de lampes installées dans le logement et de leur puissance.",
  },
  tips17: {
    highlight:
      "L’un de vos sites affiche le message « erreur technique mesures » ?",
    content:
      "Il s’agit d’une saturation des serveurs d’Enedis souvent rétablie quelques minutes jusqu’à 2 heures après. N’oubliez donc pas de relancer l’extraction.",
  },
  tips18: {
    highlight:
      "Vous pouvez modifier les paramètres techniques et financiers de chaque technologie ",
    content:
      "en cliquant sur le logo de la technologie concernée puis sur configuration avancée",
  },
  tips19: {
    highlight:
      "Vous souhaitez étudier de nouveaux projets mais votre compte est saturé",
    content:
      "libérez de la place en supprimant les anciens projets dans « mon compte » > « mes sites ».",
  },
};

export const en_tips = {
  tips1: {
    highlight:
      "When creating a new site, you can simply use a building or firm name instead of an actual adress",
    content: "for instance `Stade de France`",
  },
  tips2: {
    highlight: "Energy prices ",
    content: "are expressed in c€ / kWh, not in € / kWh",
  },
  tips3: {
    highlight: "Having trouble finding offpeak hour prices on your bill ?",
    content:
      "If you have trouble finding your offpeak hour prices on your winter bill, just use your winter peak hour prices instead. (for France only)",
  },
  tips4: {
    highlight: "Electricity travels almost at the speed of light,",
    content: "which is around 299 792 458 m/s.",
  },
  tips5: {
    highlight: "Even if your data ranges over less than a year",
    content:
      "your project's costs and revenues will be forecast and displayed for a full year in the results",
  },

  tips7: {
    highlight: "Don't get confused :",
    content:
      "Self-consumption rate = the share of produced photovoltaic energy that is consumed locally / Self-production rate = the share of energy coming from solar panels in the energy surplus",
  },
  tips8: {
    highlight:
      "The Sun produces 15 000 times more energy than what the world needs each year",
    content:
      "The amount of sunlight reaching the Earth every minute is enough to fulfill the world's energy needs for a whole year",
  },
  tips9: {
    highlight: "A question, or a suggestion ?",
    content: "Contact us by chat, we'll answer right away !",
  },
  tips10: {
    highlight: "Optiwize",
    content:
      "allows you to simulate various financing schemes, simple or hybrid systems, and contexts with the same tool.",
  },
  tips11: {
    highlight: "Optiwize",
    content:
      "allows you to carry out a complete study, from data extraction, costs analysis, to sizing optimisation and business plan",
  },
  tips12: {
    highlight: "Consult our User's Guide",
    content: "by clicking the « ? help » logo in the top right corner",
  },
  tips13: {
    highlight:
      "You can add your own custom logo from My Account => Customization",
    content: "so that it appears in the top left corner of your PDF files",
  },
  tips14: {
    highlight: "Create default scenarios from My Account => Default settings",
    content: "and apply them when creating a new scenario",
  },
  tips15: {
    highlight: "To compare different versions of a same scenario",
    content:
      "duplicate the scenario than change a few parameters, and compare the copy to the original",
  },
  tips16: {
    highlight: "Did you know electricity meters haven't always been around ?",
    content:
      "At the end of the XIXth century, those using electricity were charged based on the number and the power of the lightbulbs they owned",
  },
  tips17: {
    highlight: "One of your sites displays SGT500 | Erreur Technique Mesures ?",
    content:
      "This means Enedis's servers are having trouble answering to all their users' requests. This situation may take up to 2h to be solved. Don't forget to try again later.",
  },
  tips18: {
    highlight: "You can tweak every technology's settings",
    content:
      "by clicking on the logo of the technology, and then 'Advanced configuration'",
  },
  tips19: {
    highlight: "You want to add more projects but you have reach your limit",
    content:
      "free up some space by deleting old projects from My Account => My Sites",
  },
};
