module.exports = {
  fr: {
    consumer: {
      Solution: "Solution",
      PV: "PV",
      PV2: "PV2",
      Réseau: "Réseau",
      Eolien: "Eolien",
      Batterie: "Batterie",
      Conversion: "Conversion",
      "Pile à combustible": "Pile à combustible",
      Electrolyse: "Electrolyse",
      "Stockage H2": "Stockage H2",
      ECS: "ECS",
      "ECS Power": "Puissance ECS",
      "ECS Capacity": "Stockage ECS",
      ThermalStorage: "Stockage thermique",
      "Stockage Virtuel": "Stockage Virtuel",
      Generateur: "Generateur",
      "Groupe électrogène": "Groupe électrogène",
      "Stockage virtuel": "Stockage virtuel",
      "Taux d'autoproduction": "Taux d'autoproduction",
      "Réduction CO2": "Réduction CO2",
      "Alimentation secours - Talon": "Alimentation secours - Talon",
      "Alimentation secours - Médiane": "Alimentation secours - Médiane",
      "Alimentation secours - Totalité": "Alimentation secours - Totalité",
      Effacement: "Effacement",
      "Revenus mécanisme de capacité": "Revenus mécanisme de capacité",
      "Revenus mécanisme de capacité année de projection":
        "Revenus mécanisme de capacité année de projection",
      "Revenus complément de rémunération":
        "Revenus complément de rémunération",
      "Revenus complément de rémunération année de projection":
        "Revenus complément de rémunération année de projection",
      Energie: "Energie",
      Puissance: "Puissance",
      "Revenus SPOT": "Revenus SPOT",
      "Revenus SPOT année de projection": "Revenus SPOT année de projection",
      "Puissance crête": "Puissance crête",
      Superficie: "Superficie",
      "Revente PV": "Revente PV",
      "Revente PV année de projection": "Revente PV année de projection",
      "Revente PV2": "Revente PV2",
      "Revente PV2 année de projection": "Revente PV2 année de projection",
      "Taux d'autoconsommation": "Taux d'autoconsommation",
      Bridage: "Bridage",
      "Achat PPA": "Achat PPA",
      "Achat PPA année de projection": "Achat PPA année de projection",
      Facture: "Facture",
      "Facture année de projection": "Facture année de projection",
      "Puissances souscrites": "Puissances souscrites",
      "Puissances souscrites année de projection":
        "Puissances souscrites année de projection",
      Hauteur: "Hauteur",
      "Revente Eolien": "Revente Eolien",
      "Revente Eolien année de projection":
        "Revente Eolien année de projection",
      Capacité: "Capacité",

      "Achats H2": "Achats H2",
      "Achats H2 année de projection": "Achats H2 année de projection",
      "Ventes H2": "Ventes H2",
      "Ventes H2 année de projection": "Ventes H2 année de projection",
      "Achats de carburant": "Achats de carburant",
      "Achats de carburant année de projection":
        "Achats de carburant année de projection",
      PVWithVS: "PV via le stockage virtuel",
      "Bornes de recharge": "Bornes de recharge",
      selfConsumedTech: "{{tech}} autoconsommé",
    },
    supplier: {
      Solution: "Solution",
      PV: "PV",
      PV2: "PV2",
      Eolien: "Eolien",
      Batterie: "Batterie",
      Conversion: "Conversion",
      "Pile à combustible": "Pile à combustible",
      Electrolyse: "Electrolyse",
      "Stockage H2": "Stockage H2",
      Valeur: `Valeur`,
      "Temps écoulé": "Temps écoulé",
      "Puissance crête": "Puissance crête",
      "Nombre de panneaux": "Nombre de panneaux",
      Superficie: `Superficie`,
      Energie: `Energie`,
      Capex: `Capex`,
      Opex: `Opex`,
      "Energie SPOT": "Energie SPOT",
      "Revenu SPOT": "Revenu SPOT",
      "Revenu SPOT année de projection": "Revenu SPOT année de projection",
      "Complément de rémunération": "Complément de rémunération",
      "Complément de rémunération année de projection":
        "Complément de rémunération année de projection",
      "Energie PPA": "Energie PPA",
      "Revenu PPA": "Revenu PPA",
      "Revenu PPA année de projection": "Revenu PPA année de projection",
      "Puissance mécanisme de capacité": "Puissance mécanisme de capacité",
      "Puissance mécanisme de capacité année de projection":
        "Puissance mécanisme de capacité année de projection",
      "Revenu mécanisme de capacité": "Revenu mécanisme de capacité",
      "Revenu mécanisme de capacité année de projection":
        "Revenu mécanisme de capacité année de projection",
      Rendement: "Rendement",
      "Complément PPA - PV": "Complément PPA - PV",
      "Complément PPA - PV année de projection":
        "Complément PPA - PV année de projection",
      "Complément PPA - PV2": "Complément PPA - PV2",
      "Complément PPA - PV2 année de projection":
        "Complément PPA - PV2 année de projection",
      Réseau: "Réseau",
      Facture: "Facture",
      "Facture année de projection": "Facture année de projection",
      "Puissance raccordement": "Puissance raccordement",
      "Capex Raccordement": "Capex Raccordement",
      "Opex Raccordement": "Opex Raccordement",
      Type: "Type",
      "Nombre d'éoliennes": "Nombre d'éoliennes",
      "Puissance nominale": "Puissance nominale",
      Hauteur: "Hauteur",
      "Complément PPA - Eolien": "Complément PPA - Eolien",
      "Complément PPA - Eolien année de projection":
        "Complément PPA - Eolien année de projection",
      Capacité: "Capacité",
      Pertes: "Pertes",
      "Revenu FCR": "Revenu FCR",
      "Revenu FCR année de projection": "Revenu FCR année de projection",
      Puissance: "Puissance",
      "Revenu SPOT année 1": "Revenu SPOT année 1",
      "Revenu PPA année 1": "Revenu PPA année 1",
      "Achats H2": "Achats H2",
      "Ventes H2": "Ventes H2",
      "Ventes H2 année de projection": "Ventes H2 année de projection",
      "Ventes Stockage Thermique": "Ventes Stockage Thermique",
      "Ventes Stockage Thermique année de projection":
        "Ventes Stockage Thermique année de projection",
    },
  },
  en: {
    consumer: {
      Solution: "Solution",
      PV: "Photovoltaic",
      PV2: "Photovoltaic 2",
      Réseau: "Network",
      Eolien: "Wind turbine",
      Batterie: "Battery",
      Conversion: "Conversion",
      "Pile à combustible": "Fuel cell",
      Electrolyse: "Electrolysis",
      "Stockage H2": "H2 Storage",
      ECS: "ECS",
      "ECS Power": "ECS Power",
      "ECS Capacity": "ECS Capacity",
      ThermalStorage: "Thermal Storage",
      "Stockage Virtuel": "Virtual Storage",
      Generateur: "Engine-generator",
      "Groupe électrogène": "Engine-generator",
      "Stockage virtuel": "Virtual storage",

      "Taux d'autoproduction": "Self-production rate",
      "Réduction CO2": "CO2 reduction",
      "Alimentation secours - Talon": "Backup power - Idle",
      "Alimentation secours - Médiane": "Backup power - Median",
      "Alimentation secours - Totalité": "Backup power - Total",
      Effacement: "Withdrawal",
      "Revenus mécanisme de capacité": "Capacity income",
      "Revenus mécanisme de capacité année de projection":
        "Capacity income - projection year",
      "Revenus complément de rémunération": "Complement income",
      "Revenus complément de rémunération année de projection":
        "Complement income - projection year",
      Energie: "Energy",
      Puissance: "Power",
      "Revenus SPOT": "SPOT income",
      "Revenus SPOT année de projection": "SPOT income - projection year",
      "Puissance crête": "Peak power",
      Superficie: "Surface",
      "Revente PV": "Photovoltaic sales",
      "Revente PV année de projection": "Photovoltaic sales - projection year",
      "Revente PV2": "Photovoltaic 2 sales",
      "Revente PV2 année de projection":
        "Photovoltaic 2 sales - projeciton year",
      "Taux d'autoconsommation": "Self-consumption rate",
      Bridage: "Throttling",
      "Achat PPA": "PPA purchase",
      "Achat PPA année de projection": "PPA purchase - projection year",
      Facture: "Bill",
      "Facture année de projection": "Bill - projection year",
      "Puissances souscrites": "Subscribed powers",
      "Puissances souscrites année de projection":
        "Subscribed powers - projection year",
      Hauteur: "Height",
      "Revente Eolien": "Wind turbine sales",
      "Revente Eolien année de projection":
        "Wind turbine sales - projection year",
      Capacité: "Capacity",
      "Achats H2": "H2 purchase",
      "Achats H2 année de projection": "H2 purchase - projection year",
      "Ventes H2": "H2 sales",
      "Ventes H2 année de projection": "H2 sales - projection year",
      "Ventes Stockage Thermique": "Thermal Storage sales",
      "Ventes Stockage Thermique - année de projection":
        "Thermal Storage - projection year",
      "Achats de carburant": "Fuel purchase",
      "Achats de carburant année de projection":
        "Fuel purchase - projection year",
      PVWithVS: "PV via Virtual storage",
      "Bornes de recharge": "Charging stations",
      selfConsumedTech: "Self-consumed {{tech}}",
    },
    supplier: {
      Solution: "Solution",
      PV: "Photovoltaic",
      PV2: "Photovoltaic 2",
      Eolien: "Wind turbine",
      Batterie: "Battery",
      Conversion: "Conversion",
      "Pile à combustible": "Fuel cell",
      Electrolyse: "Electrolysis",
      "Stockage H2": "H2 Storage",
      Valeur: `Value`,
      "Temps écoulé": "Elapsed time",
      "Puissance crête": "Peak power",
      "Nombre de panneaux": "Number of panels",
      Superficie: `Surface`,
      Energie: `Energy`,
      Capex: `Capex`,
      Opex: `Opex`,
      "Energie SPOT": "SPOT energy",
      "Revenu SPOT": "SPOT income",
      "Revenu SPOT année de projection": "SPOT income - projection year",
      "Complément de rémunération": "Complement",
      "Complément de rémunération année de projection":
        "Complement - projection year",
      "Energie PPA": "PPA energy",
      "Revenu PPA": "PPA income",
      "Revenu PPA année de projection": "PPA income - projection year",
      "Puissance mécanisme de capacité": "Capacity power",
      "Puissance mécanisme de capacité année de projection":
        "Capacity power - projection year",
      "Revenu mécanisme de capacité": "Capacity income",
      "Revenu mécanisme de capacité année de projection":
        "Capacity income - projection year",
      Rendement: "Yield",
      "Complément PPA - PV": "Complement PPA - Photovoltaic",
      "Complément PPA - PV année de projection":
        "Complement PPA - Photovoltaic projection year",
      "Complément PPA - PV2": "Complement PPA - Photovoltaic2",
      "Complément PPA - PV2 année de projection":
        "Complement PPA - Photovoltaic projection year",
      Réseau: "Grid",
      Facture: "Bill",
      "Facture année de projection": "Bill projection year",
      "Puissance raccordement": "Connection power",
      "Capex Raccordement": "Connection Capex",
      "Opex Raccordement": "Connection Opex",
      Type: "Type",
      "Nombre d'éoliennes": "Number of windmills",
      "Puissance nominale": "Nominal power",
      Hauteur: "Height",
      "Complément PPA - Eolien": "PPA complement - Wind turbine",
      "Complément PPA - Eolien année de projection":
        "PPA complement - windmill projection year",
      Capacité: "Capacity",
      Pertes: "Losses",
      "Revenu FCR": "FCR income",
      "Revenu FCR année de projection": "FCR income projection year",
      Puissance: "Power",
      "Revenu SPOT année 1": "SPOT income year 1",
      "Revenu PPA année 1": "PPA income year 1",
      "Achats H2": "H2 purchase",
      "Ventes H2": "H2 sales",
      "Ventes H2 année de projection": "H2 sales projection year",
    },
  },
};
